@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	box-sizing: border-box;
}

@font-face {
	font-family: 'Maven Pro';
	src: local('Maven Pro'),
		url(./assets/fonts/MavenPro-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'Maven Pro';
	font-weight: 500;
	src: local('Maven Pro'),
		url(./assets/fonts/MavenPro-Medium.ttf) format('truetype');
}

@font-face {
	font-family: 'Maven Pro';
	font-weight: 600;
	src: local('Maven Pro'),
		url(./assets/fonts/MavenPro-SemiBold.ttf) format('truetype');
}

@font-face {
	font-family: 'Maven Pro';
	font-weight: 700;
	src: local('Maven Pro'),
		url(./assets/fonts/MavenPro-Bold.ttf) format('truetype');
}

@font-face {
	font-family: 'Maven Pro';
	font-weight: 800;
	src: local('Maven Pro'),
		url(./assets/fonts/MavenPro-ExtraBold.ttf) format('truetype');
}

@font-face {
	font-family: 'Maven Pro';
	font-weight: 900;
	src: local('Maven Pro'),
		url(./assets/fonts/MavenPro-Black.ttf) format('truetype');
}

body {
	margin: 0;
	font-family: 'Maven Pro';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scroll-behavior: smooth;
	/* overflow: hidden; */
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

h2 {
	font-size: large;
	font-weight: bold;
}

h3 {
	font-size: medium;
	font-weight: bold;
}

ul,
li {
	list-style-type: circle;
}

th,
td {
	padding: 15px;
	text-align: left;
}
